import React, { useState } from 'react'

import featuredPlugins from './featuredPlugins.module.css'

export default function Component () {
  const [selectedPlugin, setSelectedPlugin] = useState(0)

  const plugins = [
    {
      name: 'End Portal Frame',
      description:
        'The end portal generates naturally in stronghold portal rooms over a pool of lava with a staircase containing a silverfish.',
      image: 'https://via.placeholder.com/250'
    },
    {
      name: 'End Stone',
      description: 'Lorem ipsum dolor end stone.',
      image: 'https://via.placeholder.com/500'
    },
    {
      name: 'Dragon Egg',
      description: 'Lorem ipsum dolor dragon egg.',
      image: 'https://via.placeholder.com/500'
    },
    {
      name: 'Purpur Block',
      description: 'Lorem ipsum dolor purpur block.',
      image: 'https://via.placeholder.com/500'
    }
  ]

  const { image, description, name } = plugins[selectedPlugin]
  console.log(image, description)

  const mapNames = plugins => {
    return plugins.map(({ name }, i) => (
      <li key={i} className='w-full'>
        <button
          className={`h-16 w-32 lg:w-full bg-gray-700 font-bold text-gray-200 text-center p-2 xl:p-4 hover:bg-gray-500 hover:text-white ${
            i === 0 ? 'rounded-l-lg md:rounded-t-lg md:rounded-l-none' : ''
          } ${
            i === plugins.length - 1
              ? 'rounded-r-lg md:rounded-b-lg md:rounded-r-none'
              : ''
          } ${
            i !== plugins.length - 1 ? `${featuredPlugins.nameBorder}` : ''
          }   ${i === selectedPlugin ? `${featuredPlugins.activePlugin}` : ''}`}
          onClick={() => {
            setSelectedPlugin(i)
          }}>
          {name}
        </button>
      </li>
    ))
  }

  return (
    <div className={`${featuredPlugins.container} mt-6 xl:mt-12`}>
      <div className='mb-10 md:mb-0'>
        <ul
          className={`flex items-center overflow-y-scroll md:flex-col ${
            featuredPlugins.names
          }`}>
          {mapNames(plugins)}
        </ul>
      </div>
      <div className={featuredPlugins.information}>
        <img
          src={image}
          alt={name}
          className={`rounded-t-lg object-cover w-full ${
            featuredPlugins.image
          }`}
        />
        <div className='bg-blue-700 p-4 text-center rounded-b-lg'>
          <p className='text-sm'>{description}</p>
        </div>
      </div>
    </div>
  )
}
