import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import BackgroundImage from 'gatsby-background-image'
import Button from '../components/button/button'
import PremadePlugin from '../components/premadePlugin/premadePlugin'
import FeaturedPlugins from '../components/featuredPlugins/featuredPlugins'

import Fade from 'react-reveal/Fade'

import index from './index.module.css'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "backgrounds/space.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaceman: file(relativePath: { eq: "spaceman.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='Home' />
      <BackgroundImage
        Tag='section'
        className={`flex items-center bg-center ${index.sectionOne}`}
        fluid={data.background.childImageSharp.fluid}
        backgroundColor={`#040e18`}>
        <div className='container'>
          <div className='md:-mx-3 md:flex md:items-center'>
            <div className='md:w-11/12 md:px-3 lg:w-6/12 z-30'>
              <h1 className='mb-5'>
                <span className='font-bold'>StellarDev</span> website is coming soon! Stay tuned
              </h1>
              <h3>
                This astronaut is collecting the parts to build it
              </h3>
              <div className='mt-8 md:flex md:items-center'>
                <Button
                  type='int'
                  loc='#featured-projects'
                  btnType='primary'
                  className='mb-2 md:mb-0 md:mr-3'>
                  View <span className='font-bold'>Featured Projects</span>
                </Button>
                <Button type='ext' loc='/plugins' btnType='secondary'>
                  Browse <span className='font-bold'>Plugins</span>
                </Button>
              </div>
            </div>
            <div className='md:w-6/12 md:px-3 h-full relative fixed w-screen '>
              <Fade top>
                <Image
                  fluid={data.spaceman.childImageSharp.fluid}
                  className={index.spaceman}
                  draggable={false}
                  imgStyle={{ zIndex: -10 }}
                />
              </Fade>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section className='container py-10 lg:py-24' id='featured-projects'>
        <Fade bottom>
          <h2>Featured Projects</h2>
        </Fade>
        <FeaturedPlugins />
      </section>
      <section className='container py-10 lg:py-12'>
        <Fade bottom>
          <h2 className='mb-6'>Our collection of Premade Plugins</h2>
          <Button type='ext' loc='/plugins' btnType='primary' className='mb-2'>
            Browse all <span className='font-bold'>Plugins</span>
          </Button>
        </Fade>
        <div className={`${index.premadePluginsContainer} my-8`}>
          <PremadePlugin
            name='The End?'
            description='Enter the End Portal'
            to='https://google.com'
          />
          <PremadePlugin
            name='The Next Generation'
            description='Hold the Dragon Egg'
            to='https://google.com'
          />

          <PremadePlugin
            name='Content'
            description='Description'
            to='https://google.com'
          />
          <PremadePlugin
            name='Content'
            description='Description'
            to='https://google.com'
          />
          <PremadePlugin
            name='Content'
            description='Description'
            to='https://google.com'
          />
          <PremadePlugin
            name='Content'
            description='Description'
            to='https://google.com'
          />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
