import React from 'react'

export default function premadePlugin ({ name, description, to }) {
  return (
    <a
      className='px-4 flex flex-col justify-center bg-gray-600 block rounded-lg hover:bg-gray-500'
      target='_blank'
      rel='noopener'
      href={to}>
      <h3 className='font-bold mb-1'>{name}</h3>
      <p className='text-sm opacity-75'>{description}</p>
    </a>
  )
}
